import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons"
import clsx from "clsx"

import "../styles/podcast.css"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Interview } from "../types"
// import InterviewCard from "../components/InterviewCard"
// import Button from "../components/Button"
import OnScreen from "../components/OnScreen"

interface PageContext {
  currentPage: number
  numPages: number
}

interface Props {
  data: any
  pageContext: PageContext
}

// TODO: get new social icons for hero
const InterviewsPage: FunctionComponent<Props> = ({ data, pageContext }) => {
  const latestEpisode = data.latestEpisode.edges[0].node as Interview
  const interviews = data.allContentfulInterview.edges.map(
    (elem: any) => elem.node
  ) as Interview[]

  // const [activePodcastId, setActivePodcastId] = useState("")
  // const [showForm, setShowForm] = useState(false)
  // const [duration, setDuration] = useState(0)
  // const [isPlaying, setIsPlaying] = useState(false)
  // const [seekPosition, setSeekPosition] = useState(0)
  // const [timeElapsed, setTimeElapsed] = useState("00:00:00")
  const [searchTerm, setSearchTerm] = useState("")
  const [searchResult, setSearchResult] = useState<Interview[] | null>(
    interviews
  )

  const playerRef = useRef<HTMLAudioElement | null>(null)

  const formatTime = useCallback((time: number) => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time - hours * 3600) / 60)
    const seconds = Math.floor(time - hours * 3600 - minutes * 60)

    return `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}`
  }, [])

  // useEffect(() => {
  //     function onSeek(event: Event) {
  //         const target = event.target as HTMLAudioElement

  //         setSeekPosition((target.currentTime / target.duration) * 100)
  //         setTimeElapsed(formatTime(target.currentTime))
  //     }

  //     const player = playerRef.current

  //     if (player) {
  //         player.addEventListener("timeupdate", onSeek)
  //     }

  //     return () => {
  //         if (player) {
  //             player.removeEventListener("timeupdate", onSeek)
  //         }
  //     }
  // }, [formatTime])

  // function onPlayClick() {
  //     if (playerRef.current) {
  //         setIsPlaying(!isPlaying)

  //         if (isPlaying) {
  //             playerRef.current.pause()
  //             setActivePodcastId("")
  //         } else {
  //             playerRef.current.play()
  //             setActivePodcastId(latestEpisode.id)
  //         }
  //     }
  // }

  const handleSearch = (value: any) => {
    setSearchTerm(value)
    if (value !== "") {
      let result = interviews.filter(interview =>
        interview.title.toLowerCase().includes(value.toLowerCase())
      )
      setSearchResult(result)
    } else {
      setSearchResult(interviews)
    }
  }

  return (
    <Layout absoluteHeader>
      <SEO
        title="Interviews of Will Moore"
        description="Checkout all Interviews of Will Moore to find out he gamified his life and reached new levels of success in life."
        link="https://mooremomentum.com/interview-of-will-moore/"
      />

      <div className="overflow-hidden">
        <div className="relative">
          <div className="hidden absolute bg-black bg-opacity-60 inset-0 z-10 sm:block lg:block" />

          <div className="hidden lg:block">
            <StaticImage
              alt=""
              className="hero"
              placeholder="blurred"
              src="../images/Emmett-Happy-Town-V2.png"
            />
          </div>

          <div className="sm:hidden">
            <StaticImage
              alt="Podcasts Mooremomentum"
              className="hero"
              placeholder="blurred"
              src="../images/podcast-hero-mobile.png"
            />
          </div>

          <div className="hidden sm:block lg:hidden">
            <StaticImage
              alt="Will Moore Standing on mountaintop"
              className="hero"
              placeholder="blurred"
              src="../images/Emmett-Happy-Town-V2.png"
            />
          </div>

          <div className="absolute flex inset-0 items-center justify-center z-10">
            <div className="px-4 relative text-center text-white">
              <p className="mb-4 uppercase">Welcome to</p>

              <h1 className="font-Orbitron text-4xl md:text-5xl lg:text-6xl">
                Interviews Of Will Moore
              </h1>

              {/* <StaticImage
                                alt="Will Moore Standing on mountaintop"
                                className="w-36"
                                placeholder="blurred"
                                src="../images/signature.png"
                            /> */}

              <p className="mb-4 mt-12 uppercase">Listen &amp; Subscribe On</p>

              <div className="gap-8 grid grid-cols-2 sm:grid-cols-4">
                <div className="bg-white flex h-16 items-center justify-center p-1 rounded-md shadow-lg w-full">
                  <a href="https://podcasts.apple.com/us/podcast/gamify-your-life/id1510028611">
                    <StaticImage
                      alt="Apple Podcast Logo"
                      className="max-h-12"
                      src="../images/apple-podcasts.png"
                    />
                  </a>
                </div>

                <div className="bg-white flex h-16 items-center justify-center p-1 rounded-md shadow-lg w-full">
                  <a href="https://www.youtube.com/channel/UCBBnybi_zZquxqB9rZIP6Zw">
                    <StaticImage
                      alt="Youtube Logo"
                      className="max-h-12"
                      src="../images/youtube.png"
                    />
                  </a>
                </div>

                <div className="bg-white flex h-16 items-center justify-center p-1 rounded-md shadow-lg w-full">
                  <a href="https://open.spotify.com/show/5TW9qSsbO23gsWuRX2mTYl">
                    <StaticImage
                      alt="Spotify Logo"
                      className="max-h-12"
                      src="../images/spotify.png"
                    />
                  </a>
                </div>

                {/* <div className="bg-white flex h-16 items-center justify-center rounded-md shadow-lg w-full">
                  <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8xZDk4Zjc4NC9wb2RjYXN0L3Jzcw==">
                    <StaticImage
                      alt="Google Podcast Logo"
                      className="max-h-12"
                      src="../images/google-podcasts.png"
                    />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* <OnScreen
                    className="px-4 text-center md:text-left z-20"
                    classToAdd="slide-in-right"
                >
                    <div className="bg-blue max-w-6xl mx-auto -mt-24 p-6 relative rounded-2xl shadow-xl z-20">
                        <div className="gap-6 grid grid-cols-1 sm:grid-cols-2 lg:gap-8 z-20">
                            <div>
                                <p className="font-bold mb-4 text-sm text-white">
                                    LATEST EPISODE
                                </p>

                                <GatsbyImage
                                    alt={latestEpisode.coverImage.description}
                                    className="mb-6 w-48"
                                    image={latestEpisode.coverImage.gatsbyImageData}
                                />

                                <h2 className="font-Orbitron text-white text-xl md:text-2xl">
                                    {latestEpisode.title}
                                </h2>
                            </div>

                            <div className="self-center text-white">
                                <div className="mb-8 text-center">
                                    <div className="border border-white inline-block p-1 rounded-full">
                                        <button
                                            className="bg-white flex items-center justify-center h-16 rounded-full transition-opacity w-16 hover:opacity-70"
                                            type="button"
                                            onClick={onPlayClick}
                                        >
                                            <FontAwesomeIcon
                                                className="text-yellow text-2xl"
                                                icon={isPlaying ? faPause : faPlay}
                                            />
                                        </button>
                                    </div>
                                </div>

                                <audio
                                    controls
                                    style={{ display: "none" }}
                                    ref={playerRef}
                                    onCanPlay={() =>
                                        setDuration(playerRef.current?.duration || 0)
                                    }
                                >
                                    <source src={latestEpisode.mediaUrl} type="audio/mpeg" />
                                </audio>

                                <div className="mb-4">
                                    <div className="bg-gray h-1 mb-3 relative w-full">
                                        <div
                                            className="absolute bg-yellow h-1 left-0"
                                            style={{ width: `${seekPosition}%` }}
                                        />
                                    </div>

                                    <div className="flex justify-between">
                                        <p className="text-sm">{timeElapsed}</p>

                                        <p className="text-sm">{formatTime(duration)}</p>
                                    </div>
                                </div>

                                <p className="mb-4 mt-8 text-sm">{latestEpisode.blurb}</p>

                                <Link
                                    className="transition-opacity underline hover:opacity-70"
                                    to={`/podcast/episode/${latestEpisode.slug}`}
                                >
                                    Read More
                                </Link>
                            </div>
                        </div>
                    </div>
                </OnScreen> */}
      </div>

      <div className="max-w-6xl mb-16 mx-auto px-4 mt-16">
        <form className="mb-10">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <input
              className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              type="search"
              id="blog-search"
              placeholder="Search Blogs"
              required
              value={searchTerm}
              onChange={e => handleSearch(e.target.value)}
            />
            {/* <button type="submit" className="text-white absolute right-2.5 bottom-2.5 bg-blue hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">Search</button> */}
          </div>
        </form>

        <h2 className="font-Orbitron mb-8 text-center text-2xl md:text-4xl">
          Most Recent Episodes
        </h2>

        <div className="gap-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {/* { interviews.map(elem => (
                        <OnScreen key={elem.id} classToAdd="scale-in-center">
                            <InterviewCard
                                activePodcastId={activePodcastId}
                                podcast={elem}
                                onPodcastPlayStateChanged={setActivePodcastId}
                            />
                        </OnScreen>
                    ))} */}
          {searchResult &&
            searchResult.map(elem => (
              <OnScreen
                key={elem.id}
                className="bg-white h-full p-4 rounded-md shadow-lg"
                classToAdd="scale-in-center"
              >
                <Link to={elem.slug}>
                  <GatsbyImage
                    alt={elem.coverImage.description}
                    className="mb-8"
                    image={elem.coverImage.gatsbyImageData}
                  />

                  <div>
                    <h3 className="font-Orbitron text-center sm:text-lg">
                      {elem.title}
                    </h3>
                  </div>
                </Link>
              </OnScreen>
            ))}
        </div>

        <div className="flex justify-center mt-12">
          <Link
            aria-disabled={pageContext.currentPage === 1}
            className={clsx(
              "font-bold mr-4",
              pageContext.currentPage > 1
                ? "transition-colors hover:text-blue"
                : "text-gray-dark"
            )}
            to={
              pageContext.currentPage === 2
                ? "/podcast"
                : `/podcast/${pageContext.currentPage - 1}`
            }
            onClick={event => {
              if (
                event.currentTarget.getAttribute("aria-disabled") === "true"
              ) {
                event.preventDefault()
              }
            }}
          >
            Previous
          </Link>

          <Link
            aria-disabled={pageContext.currentPage === pageContext.numPages}
            className={clsx(
              "font-bold mr-4",
              pageContext.currentPage < pageContext.numPages
                ? "transition-colors hover:text-blue"
                : "text-gray-dark"
            )}
            to={`/podcast/${pageContext.currentPage + 1}`}
            onClick={event => {
              if (
                event.currentTarget.getAttribute("aria-disabled") === "true"
              ) {
                event.preventDefault()
              }
            }}
          >
            Next
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int, $limit: Int) {
    latestEpisode: allContentfulInterview(
      limit: 1
      sort: { fields: [publishedDate], order: DESC }
    ) {
      edges {
        node {
          blurb
          coverImage {
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
          id
          link
          title
          publishedDate
          slug
          mediaUrl
        }
      }
    }
    allContentfulInterview(
      skip: $skip
      limit: $limit
      sort: { fields: [publishedDate], order: DESC }
    ) {
      edges {
        node {
          blurb
          coverImage {
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
          id
          link
          publishedDate
          title
          slug
          mediaUrl
        }
      }
    }
  }
`

export default InterviewsPage
